import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { Franchise } from '../../models/franchise.model';
import { TransferDataService } from '../../services/transfer-data.service';

export type AvatarSize = (
    'default'
    | 'tiny'
    | 'smaller'
    | 'small'
    | 'medium'
    | 'large'
    | 'big'
    | 'bigger'
    | 'huge'
);
@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnDestroy {
    protected readonly DEFAULT_URL_WIDTH: number = 36 * 2;
    protected innerPhoto: string = '';
    protected innerUser: User|null = new User();
    protected innerFranchise: Franchise|null = new Franchise();
    protected innerSize: AvatarSize = 'default';

    @Input() @HostBinding('class.auto-width') autoWidth: boolean = false;


    imageLoaded: boolean = true;
    imageError: boolean = false;

    initials: string = '';
    sharedData: string = '';
    urlWidth: number = this.DEFAULT_URL_WIDTH;
    nameToSizeMap: {[name in AvatarSize]: number} = {
        'tiny': 36*2,
        'default': 36*2,
        'smaller': 42*2,
        'small': 60*2,
        'medium': 80*2,
        'large': 100*2,
        'big': 150*2,
        'bigger': 200*2,
        'huge': 350*2,
    };

    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    @Input()
    set photo(val: string) {
        if (this.innerPhoto !== val) {
            this.imageError = false;
            this.imageLoaded = true;
            this.innerPhoto = val;
        }
    }
    get photo(): string {
        return this.innerPhoto;
    }

    @Input()
    set user(val: User|null) {
        if (this.innerUser !== val) {
            if (this.innerUser?.image !== val?.image) {
                this.imageError = false;
                this.imageLoaded = true;
            }
            this.innerUser = val;
            this.initials = this.computeInitials();
        }
    }
    get user(): User|null {
        return this.innerUser;
    }

    set franchise(val: User|null) {
        if (this.innerUser !== val) {
            if (this.innerUser?.image !== val?.image) {
                this.imageError = false;
                this.imageLoaded = true;
            }
            this.innerUser = val;
            this.initials = this.computeInitials();
        }
    }
    get franchise(): User|null {
        return this.innerUser;
    }

    @Input() enableRemoving: boolean = false;
    @Input() urlResize: boolean = true; // adds ?w=[number] to the end of URL's
    @Input() removingTitle: string = 'Remove avatar';
    @Input() status: boolean|null = null;
    @Input() showEmpty: boolean = false;
    @Input() withShadow: boolean = false;
    @Input() label: string = '';
    @Input() className: string = '';
    @Input() type: ('circle'|'drop'|'dropAlt') = 'circle';
    @Input() highlight: boolean = false;
    @Input() selected: boolean = false;
    @Input() loading: boolean = false;

    @Input()
    set size(size: AvatarSize|null) {
        if (this.innerSize !== size) {
            this.innerSize = size as any;

            if (this.innerSize === null) {
                this.autoWidth = true;
            }

            if (this.urlResize) {
                this.urlWidth = size && size in this.nameToSizeMap
                    ? this.nameToSizeMap[size]
                    : this.DEFAULT_URL_WIDTH;

                if (this.innerSize === null) {
                    // biggest possible
                    this.urlWidth = this.nameToSizeMap['huge'];
                }
            }
        }
    }
    get size(): AvatarSize {
        return this.innerSize;
    }

    constructor(private transferData: TransferDataService) {  }

    ngOnInit(): void {
        this.transferData.sharedData$
        .subscribe(sharedData => {
            this.sharedData = sharedData;
            return this.sharedData;
        } );

    }

    ngOnDestroy(): void {
    }

    onRemoveClicked(event: Event): boolean {
        event.preventDefault();
        this.onRemove.emit();
        return false;
    }

    protected computeInitials() {
        const fullName = [
            this.user?.name || '',
            this.user?.lastname || ''
        ].join(' ');
        const name: string[] = (this.label || fullName || '').trim().toUpperCase().trim().split(' ');
        let first: string = name[0] && name[0].length > 0 ? (name[0].match(/./ug) || [])[0] || '' : '';
        let second: string = name[1] && name[1].length > 0 ? (name[1].match(/./ug) || [])[0] || '' : '';
        return [first, second].join('').trim();
    }
}
