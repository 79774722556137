import { Live } from "../live/live.model";
import { Room } from "../room.model";
import { Teacher } from "../teacher.model";
import { CourseShort } from "./../course-short.model";
import { EventItem } from "./event-item.model";

export class Event {
    id?: number;
    title?: string;
    description?: string;
    room?: Room;
    teachers?: Teacher[];
    course?: CourseShort;
    events?: EventItem[];
    live?: Live;

    static fromJson(fields: any): Event {
        let item = Object.assign(new this(), fields);
        item.room = Room.fromJson(fields?.room);
        item.course = CourseShort.fromJson(fields?.course);
        item.live = Live.fromJson(fields?.live);
        item.events = fields?.events?.map((item: any) => EventItem.fromJson(item));
        item.teachers = fields?.teachers?.map((item: any) => Teacher.fromJson(item));
        return item;
    }
}
