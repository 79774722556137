import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Component, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';

export interface ModalItemsChooseComponentItem {
    label: string;
    value: number;
    subtitle?: string;
    description?: string;
    disabled?: boolean;
    selected?: boolean;
}

@Component({
    selector: 'modal-items-chooser',
    templateUrl: './modal-items-chooser.component.html',
    styleUrls: ['./modal-items-chooser.component.scss']
})
export class ModalItemsChooseComponent {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    protected innerItems: ModalItemsChooseComponentItem[] = [];

    @ViewChild('form') formRef: ElementRef|null = null;
    @Output() onAction: EventEmitter<any> = new EventEmitter();
    @Output() onSelect: EventEmitter<number[]> = new EventEmitter();
    @Output() onMoreItems: EventEmitter<{q: string, reset: boolean}> = new EventEmitter();

    @Input() headline: string = translate('Избор');
    @Input() closable: boolean = true;
    @Input() user: User|null|undefined = null;
    @Input() multipleOptions: boolean = true;

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;

    @Input()
    set items(val: ModalItemsChooseComponentItem[]) {
        if (this.innerItems !== val) {
            this.innerItems = val;

            this.selectedItems?.length && (this.innerItems = this.innerItems?.map(item => {
                item.selected = this.selectedItems.includes(item?.value);
                return item;
            }));
        }
    }
    get items(): ModalItemsChooseComponentItem[] {
        return this.innerItems;
    }

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;
    protected debounceTimeout: number = 750;
    protected searchDebounce: any = null;

    form: FormGroup|null = null;
    errors: any = {};
    search: string = '';
    message: string = '';
    selectedItems: number[] = [];

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    protected initForm(): void {
        this.errors = {};
        this.form = new FormGroup({
            items: new FormControl('', [
                Validators.required,
            ]),
        });
    }

    onCloseAction(e?: any): void {
        this.onAction.emit({action: 'close'});
    }

    onSearchEnter(event?: MouseEvent|Event): void {
        this.searchDebounce && clearTimeout(this.searchDebounce);


        this.onMoreItems.emit({q: '', reset: true});
    }

    onSearchClear(event?: MouseEvent|Event): void {
        this.search = '';

        this.onMoreItems.emit({q: '', reset: true});
    }

    onSearchDebounce(event?: MouseEvent|Event): void {
        this.searchDebounce && clearTimeout(this.searchDebounce);
        this.searchDebounce = setTimeout(() => {
            this.onMoreItems.emit({q: this.search ?? '', reset: true});
         }, this.debounceTimeout);
    }

    onScrollDown(event?: any): void {
        this.onMoreItems.emit({q: this.search ?? '', reset: false});
    }

    onSelectedItem(event: Event, item: ModalItemsChooseComponentItem): boolean {
        event && event.preventDefault();

        if (item?.disabled) {
            return false;
        }

        if (!this.multipleOptions) {
            this.selectedItems = [];
            this.items = this.items?.map(item => (item.selected = false, item));
            this.form?.get('items')?.setValue(this.selectedItems || '');
        }

        const index = this.selectedItems.indexOf(item?.value ?? 0);

        item.selected = !(item?.selected ?? false);

        if (index === -1) {
            this.selectedItems.push(item?.value ?? 0);
        } else {
            this.selectedItems.splice(index, 1);
        }

        if (this.form) {
            this.form?.get('items')?.setValue(this.selectedItems || '');
        }

        return false;
    }

    onSubmit(event?: Event): void {
        if (this.loading) {
            return;
        }

        if (this.form?.invalid) {
            this.errors = this.form?.controls;

            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            this.form.markAllAsTouched();
            return;
        }

        this.onSelect.emit(this.selectedItems);
        this.onAction.emit({action: 'submit'});
    }
}
