import { Component } from '@angular/core';
import { CookieService } from '../../services/cookie.service';

@Component({
    selector: 'modal-instructions',
    templateUrl: './modal-instructions.component.html',
    styleUrls: ['./modal-instructions.component.scss']
})
export class ModalInstructions {
    readonly COOKIE_NAME: string = 'video_intro';
    readonly COOKIE_VALUE: string = '1';
    readonly COOKIE_EXPIRE: number = 365 * 10;

    visible: boolean = false;

    constructor(
        private cookieService: CookieService,
    ) { }

    ngOnInit(): void {
        const isShownAlready = this.cookieService.get(this.COOKIE_NAME) ?? "";
        this.visible = isShownAlready !== this.COOKIE_VALUE;
    }

    onCloseAction(event?: any): void {
        try {
            this.cookieService.set(this.COOKIE_NAME, this.COOKIE_VALUE, this.COOKIE_EXPIRE, '/');
        } catch (e) {}
        this.visible = false;

    }
}

