import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ApiService } from './api.service';
import { Paginate } from '../models/paginate.model';
import { Franchise } from '../models/franchise.model';
import { BehaviorSubject, map, Observable, share } from 'rxjs';
import { FranchiseCourse } from '../models/franchise-course.model';
import { FranchiseCourseDesign } from '../models/franchise-course-design.model';
import { FranchiseCoursePublic } from '../models/franchise-course-public.model';
import { FranchiseQuery } from '../models/franchise-query.model';
import { FilterSort } from '../models/filter-sort.model';
import { FranchisePlan } from '../models/franchise-plan.model';
import { CourseDesignExtend } from '../models/course-design-extend.model';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { FranchisePreference } from '../models/franchise-preference.model';
import { FranchiseShort } from '../models/franchise-short.model';
import { AuthService } from './auth.service';
import { User } from '../models/user/user.model';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

export type FranchiseServiceSortType = (
    'id'
    | 'administrator'
    | 'name'
    | 'email'
    | 'phone'
    | 'address'
    | 'status'
    | 'updated_at'
    | 'created_at'
);

export type FranchiseServiceCourseSortType = (
    'id'
    | 'url'
    | 'title'
    | 'description'
    | 'program'
    | 'status'
    | 'weeks'
    | 'position'
    | 'created'
    | 'updated'
);

export type FranchiseServiceIndex = {
    limit?: number;
    page?: number;
    q?: string;
    sort?: FranchiseServiceSortType|FilterSort<FranchiseServiceSortType>[];

};
export type FranchiseServicePlanFilter = {
    limit?: number;
    page?: number;
    status?: ('all'|'expired'|'active');
};

export type FranchiseServiceCourseIndexIncludeType = (
    'sections'
    | 'sections.items'
);

export interface FranchiseServiceCourseIndex  {
    limit?: number;
    page?: number;
    sort?: FranchiseServiceCourseSortType|FilterSort<FranchiseServiceCourseSortType>[],
    q?: string;
    course?: number;
    status?: ('active'|'inactive');
    include?: FranchiseServiceCourseIndexIncludeType|FranchiseServiceCourseIndexIncludeType[];
};

export interface FranchiseServiceInputFranchise {
    administrator?: number;
    administrator_user?: {
        email?: string;
        password?: string;
        name?: string;
        surname?: string;
        lastname?: string;
        phone?: string;
        image?: string;
        status?: string;
    };
    plan?: number;
    logo?: string;
    image_share?: string;
    icon?: string;
    url?: string;
    town?: number;
    name: string;
    email?: string;
    phone?: string;
    facebook?: string;
    address?: string;
    homepage?: string;
    homepage_description?: string;
    homepage_background?: string;
    location_url?: string;
    description?: string;
    company_full_name?: string;
    company_tax_number?: string;
    company_address?: string;
    company_ceo?: string;
    status?: ( "active" | "inactive" );

    preferences?: FranchisePreference[];
};

export interface FranchiseServiceInputFranchiseCourseInquiry {
    name?: string;
    phone?: string;
    email?: string;
    message?: string;
}

export interface FranchiseServiceInputUpdateFranchise {
    administrator?: number;
    plan?: number;
    logo?: string;
    url?: string;
    town?: number;
    name?: string;
    email?: string;
    phone?: string;
    address?: string;
    homepage?: string;
    location_url?: string;
    company_full_name?: string;
    company_tax_number?: string;
    company_address?: string;
    company_ceo?: string;
    status?: ( "active" | "inactive" );
    preferences?: FranchisePreference[];
};

export interface FranchiseServiceIndexAvailable  {
    franchise?: number;
    status?: ('active'|'inactive');
    design?: ('kids'|'computer-science'|'web-development');
};

export interface FranchiseServiceCourseEnrollInput {
    name: string;
    email: string;
    phone: string;
    group?: number;
    parent_name?: string;
    parent_phone?: string;
    age?: number;
    school?: string;
    grade?: number;
    city?: string;
    town?: number;
    payment_type?: ('installments'|'full');
    method?: ('cash'|'transfer'|'bank'|'paypal');
    recaptcha: string;
}

export interface UploadTemplate {
    name?: string,
    filename?: string,
    mime?: string,
    size?: number,
    description?: string,
};

export interface FranchiseServiceInputInquiry {
    recaptcha: string;

    name: string;
    email: string;
    phone: string;

    subject?: string;
    message: string;
};

@Injectable({
    providedIn: 'root'
})

export class FranchiseService {
    protected renderer: Renderer2;
    protected franchise: Franchise|null = null;
    protected user: User|null = null;
    protected brandDetails: BehaviorSubject<Franchise|null> = new BehaviorSubject<Franchise|null>(null);

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private api: ApiService,
        private authService: AuthService,
        private metaService: Meta,
        private rendererFactory: RendererFactory2,
        private router: Router,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,

    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);

        this.fetchBrandDetails(this.document?.location?.hostname ?? '')
            .catch(error => {});

        this.authService.getAuthReady().subscribe(status => {
            this.user = this.authService.getUser();
            this.checkUserAndFranchise();
        });
    }

    add(data: FranchiseServiceInputFranchise): Observable<{data: Franchise}> {
        return this.api.post('/franchises', data).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    delete(id: number): Observable<{ data: any }> {
        return this.api.delete('/franchises/' + id);
    }

    /**
     * Get list of franchises
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getList(filter?: FranchiseServiceIndex, maxCacheTime?: number): Observable<Paginate<Franchise>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as FranchiseServiceSortType);

        return this.api.get('/franchises', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Franchise>(), data);
                data.data = data.data?.map((item: any)=> Franchise.fromJson(item));
                return data;
            })
        )
    }


    /**
     * Get list of plans
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getPlansList(filter?: FranchiseServicePlanFilter, maxCacheTime?: number): Observable<Paginate<FranchisePlan>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/plans', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<FranchisePlan>(), data);
                data.data = data.data?.map((item: any)=> FranchisePlan.fromJson(item));
                return data;
            })
        )
    }

    getFranchise(id: number): Observable<{data: Franchise}> {
        return this.api.get('/franchises/' + id).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    updateFranchise(id: number, data: FranchiseServiceInputUpdateFranchise): Observable<{data: Franchise}> {
        return this.api.post('/franchises/' + id, data).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    queryCompanyDetails(taxNumber: string|number): Observable<{data: FranchiseQuery}> {
        return this.api.get('/franchises/query/' + taxNumber).pipe(
            map(data => {
                data.data = FranchiseQuery.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get Franchise Courses List
     * @param id
     * @param filter
     * @returns
     */
    getCoursesList(id: number, filter?: FranchiseServiceCourseIndex, maxCacheTime?: number): Observable<Paginate<FranchiseCourse>>  {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as FranchiseServiceCourseSortType);
        filter && filter?.include?.length && (typeof filter.include === 'object') && (filter['include'] = filter.include?.join(',') as any);


        return this.api.get('/franchises/' + id + '/courses', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<FranchiseCourse>(), data);
                data.data = data.data?.map((item: any)=> FranchiseCourse.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Add new Course to Franchise
     * @param id
     * @param data
     * @returns
     */
    storeCourse(id: number, data: any): Observable<{data: FranchiseCourse}> {
        return this.api.post('/franchises/' + id + '/courses', data).pipe(
            map(data => {
                data.data = FranchiseCourse.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get a Franchise Course
     * @param id
     * @param courseId
     * @returns
     */
    getCourse(id: number, courseId: number): Observable<{data: FranchiseCourse}> {
        return this.api.get('/franchises/' + id + '/courses/' + courseId).pipe(
            map(data => {
                data.data = FranchiseCourse.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update existing Franchise Course
     * @param id
     * @param courseId
     * @param data
     * @returns
     */
    updateCourse(id: number, courseId: number, data: any): Observable<{data: FranchiseCourse}> {
        return this.api.post('/franchises/' + id + '/courses/' + courseId, data).pipe(
            map(data => {
                data.data = FranchiseCourse.fromJson(data?.data);
                return data;
            })
        );
    }

    uploadCourseTemplate(data: UploadTemplate): Observable<{data: any}> {
        return this.api.post<{data: any}>('/uploads/templates', data)
    }

    /**
     * Delete a Course from Franchise
     * @param id
     * @param courseId
     * @returns
     */
    deleteCourse(id: number, courseId: number): Observable<{data: any}> {
        return this.api.delete('/franchises/' + id + '/courses/' + courseId);
    }

    /**
     * Get Designs list for courses
     * @param id
     * @param data
     * @returns
     */
    getCourseDesigns(): Observable<{ data: FranchiseCourseDesign[] }> {
        return this.api.get('/franchises/courses/designs').pipe(
            map(response => {
                const designs = (response.data as FranchiseCourseDesign[]).map(item => FranchiseCourseDesign.fromJson(item));
                return { data: designs };
            })
        );
    }

    /**
     * Get a Course from Franchise by URL
     *
     * @param id
     * @param courseId
     * @returns
     */
    getCourseByUrl(id: number, url: string): Observable<{data: FranchiseCoursePublic}> {
        return this.api.get('/franchises/' + id + '/courses/url/' + url).pipe(
            map(data => {
                data.data = FranchiseCoursePublic.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Post franchise contact us form
     *
     * @param id Franchise ID
     * @param data
     * @returns
     */
    inquiry(id: number, data: FranchiseServiceInputInquiry): Observable<any> {
        return this.api.post(`/franchises/` + id + `/inquiry`, data);
    }

    /**
     * Post franchise contact us form
     *
     * @param id Franchise ID
     * @param data
     * @returns
     */
    courseInquiry(id: number, courseId: number, data: FranchiseServiceInputFranchiseCourseInquiry): Observable<any> {
        return this.api.post(`/franchises/` + id + `/courses/` + courseId + '/inquiry', data);
    }

    /**
     * Get a Franchise by URL
     * @param url
     * @param maxCacheTime
     * @returns
     */
    getByUrl(url: string, maxCacheTime?: number): Observable<{data: Franchise}> {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url;
        }

        const hostname = new URL(url).hostname;
        const franchiseUrl = this.isAddressLocal(hostname) ? environment?.domain : hostname;

        return this.api.get('/franchises/url/' + franchiseUrl, null, maxCacheTime).pipe(
            map(data => {
                data.data = Franchise.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get available courses (for purchase) by partner
     *
     * @param filter
     * @returns
     */
    getAvailableCourses(id: number, filter?: FranchiseServiceIndexAvailable): Observable<{data: CourseDesignExtend[]}> {
        return this.api.get('/franchises/' + id + '/courses/available', filter).pipe(
            map(response => {
                response.data = response.data?.map((item: CourseDesignExtend) => CourseDesignExtend.fromJson(item));
                return response;
            })
        );
    }

    fetchBrandDetails(url?: string): Promise<Franchise|null> {
        return new Promise((resolve, reject) => {
            if (this.brandDetails.value !== null) {
                resolve(this.franchise);
                return;
            }

            url = url ? url : (this.document?.location?.hostname ?? '');

            this.getByUrl(url).subscribe({
                next: response => {
                    this.franchise = response?.data ?? Franchise.fromJson({});
                    this.brandDetails.next(this.franchise);
                    this.checkUserAndFranchise();
                    resolve(this.franchise);
                },
                error: error => {
                    this.brandDetails.next(Franchise.fromJson({}));
                    reject(error);
                }
            });
        });
    }

    getCompanyDetails(): Observable<Franchise|null> {
        return this.brandDetails.asObservable().pipe(share());
    }

    enroll(franchiseId: number, courseId: number, data: FranchiseServiceCourseEnrollInput): Observable<any> {
        return this.api.post('/franchises/' + franchiseId + '/courses/' + courseId + '/enroll', data).pipe(
            map(response => {
                return response;
            })
        );
    }

    isAddressLocal(hostname: string): boolean {
        return /^(localhost|192\.168\.\d{1,3}\.\d{1,3}|10\.\d{1,3}\.\d{1,3}\.\d{1,3}|127\.\d{1,3}\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3})$/.test(hostname);
    }

    getLandingPageFullUrl(partner: Franchise|FranchiseShort, pageUrl: string, courseUrl: string, franchiseCourse?: FranchiseCourse): string {
        if (partner instanceof Franchise && partner?.primary) {
            try {
                return franchiseCourse?.course?.url ?? '';
            } catch (e) {
                return '';
            }
        }

        if (!pageUrl?.length || !partner?.id || !courseUrl?.length) {
            return '';
        }

        const baseUrl = window?.location?.hostname ?? environment.domain;
        const partnerUrl = (partner as Franchise)?.url ?? environment.domain;
        const baseHref = this.document?.getElementsByTagName('base')[0]?.getAttribute('href')?.replace(/\//g, '') ?? '';

        return (window?.location?.protocol  ?? 'https:') + '//' + [
                partnerUrl,
                '/' + (baseHref || ''),
                pageUrl,
            ]
            .map(url => url
                ?.split('/')
                ?.filter(x => x)
                ?.join('/'))
            .filter(x=> x)
            .join('/') + '/' + courseUrl;
    }

    buildFranchisePageUrl(partner: Franchise|FranchiseShort, pageUrl?: string, forceProtocol: (null|'https:'|'http:') = null): string {
        if (!partner?.id) {
            return '';
        }

        const partnerUrl = (partner as Franchise)?.url ?? environment.domain;
        const baseHref = this.document?.getElementsByTagName('base')[0]?.getAttribute('href')?.replace(/\//g, '') ?? '';

        return (forceProtocol ?? window?.location?.protocol ?? 'https:') + '//' + [
                partnerUrl,
                '/' + (baseHref || ''),
                pageUrl,
            ]
            .map(url => url
                ?.split('/')
                ?.filter(x => x)
                ?.join('/'))
            .filter(x=> x)
            .join('/');
    }

    protected checkUserAndFranchise(): void {
        if (this.user?.id && this.user?.franchise?.id && this.franchise?.id !== this.user?.franchise?.id) {
            this.getFranchise(this.user?.franchise?.id).subscribe(response => {
                this.franchise = response?.data ?? Franchise.fromJson({});
                this.brandDetails.next(this.franchise);
            });
        }
    }

    updateMetaTags(company: Franchise): void {
        let images = this.metaService.getTags("property='og:image'")?.length;
        if (images > 1) {
            for (let i = 0; i < images; i++) {
                this.metaService.removeTag('property="og:image"');
            }
        }

        // share image
        if (company?.image_share?.length) {
            this.metaService.updateTag({
                property: 'og:image',
                content: company?.image_share || company?.image || "",
            });
        }

        // favicon
        if (company?.icon?.length) {
            this.document
                ?.head
                ?.querySelectorAll("link[rel*='icon']")
                ?.forEach(item => this.renderer.removeChild(this.document.head, item));

            const link = this.renderer.createElement('link');
            if (link) {
                this.renderer.setProperty(link, 'rel', 'icon');
                this.renderer.setProperty(link, 'type', 'image/x-icon');
                this.renderer.setProperty(link, 'href', company?.icon ?? '');
                this.renderer.setProperty(link, 'id', 'dynamic-favicon');
                this.renderer.appendChild(this.document.head, link);
            }
        }

        const currentPath = this.router.url;
        const queryParams = this.activatedRoute.snapshot.queryParams;

        this.metaService.updateTag({
            property: 'og:url',
            content: ('https://' + (company?.url ?? '')) + currentPath + '/' + new URLSearchParams(queryParams).toString(),
        });
        this.metaService.updateTag({
            property: 'og:site_name',
            content: company?.name ?? '',
        });

        const newDescription = company?.description || '';
        this.metaService.updateTag({
            property: 'og:description',
            content: newDescription,
        });
        this.metaService.updateTag({
            name: 'description',
            content: newDescription,
        });

        const newTitle = [company?.name || ''].join(' | ');
        this.titleService.setTitle(newTitle);
        this.metaService.updateTag({
            property: 'og:title',
            content: newTitle
        });
    }

    updateCourseMetaTags(company: Franchise, course: FranchiseCoursePublic): void {
        let images = this.metaService.getTags("property='og:image'")?.length;
        if (images > 1) {
            for (let i = 0; i < images; i++) {
                this.metaService.removeTag('property="og:image"');
            }
        }

        this.metaService.updateTag({
            property: 'og:image',
            content: course?.image_share || course?.course?.image_share || course?.course?.image || company?.image_share || company?.image || "",
        });

        const urlTree = this.router.createUrlTree(['/portal/courses/', course?.url ?? '']);
        const url = this.router.serializeUrl(urlTree);

        this.metaService.updateTag({
            property: 'og:url',
            content: 'https://' + (company?.url ?? '') + url,
        });

        const newDescription = course?.description || company?.description || '';
        this.metaService.updateTag({
            property: 'og:description',
            content: newDescription,
        });
        this.metaService.updateTag({
            name: 'description',
            content: newDescription,
        });

        const newTitle = [course?.title || course?.course?.title || '', company?.name || ''].filter(i => i?.length).join(' | ');
        this.titleService.setTitle(newTitle);
        this.metaService.updateTag({
            property: 'og:title',
            content: newTitle
        });
    }
}
