import { Course } from "./course.model";
import { FranchiseCourseDesign } from "./franchise-course-design.model";
import { FranchiseCourseSection } from "./franchise-course-section.model";
import { FranchisePreference } from "./franchise-preference.model";
import { Franchise } from "./franchise.model";
import { GroupLanding } from "./group-landing.model";

export type FranchiseStatus = ('active'|'inactive');

export class FranchiseCoursePublic {
    url?: string;
    title?: string;
    description?: string;
    image_share?: string;
    franchise?: Franchise;
    course?: Course;
    groups?: GroupLanding[];
    sections?: FranchiseCourseSection[];
    design?: FranchiseCourseDesign;
    preferences?: FranchisePreference[];

    static fromJson(fields: any): FranchiseCoursePublic {
        let item = Object.assign(new this(), fields);
        item.franchise = Franchise.fromJson(fields?.franchise);
        item.course = Course.fromJson(fields?.course);
        item.groups = fields?.groups?.map((item: any) => GroupLanding.fromJson(item));
        item.sections = fields?.sections?.map((item: any) => FranchiseCourseSection.fromJson(item));
        item.design = FranchiseCourseDesign.fromJson(fields?.design);
        item.preferences = fields?.preferences?.map((item: any) => FranchisePreference.fromJson(item));

        return item;
    }

    getPreference(name: string): FranchisePreference|null {
        return this.preferences?.find(item => item?.name?.startsWith(name)) ?? null;
    }

    isPreference(name: string, value: boolean|string|number): boolean {
        return this.getPreference(name)?.value === value;
    }
}
