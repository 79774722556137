import { AfterContentInit, Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { NavigationService, NavigationServiceMenu } from '../../services/navigation.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { Franchise } from '../../models/franchise.model';
import { FranchiseService } from '../../services/franchise.service';
import { isPlatformServer } from '@angular/common';


@Component({
    selector: 'app-sidemenu',
    templateUrl: './app-sidemenu.component.html',
    styleUrls: ['./app-sidemenu.component.scss']
})
export class AppSidemenuComponent implements OnInit, AfterContentInit {
    protected subscriptions: Subscription[] = [];

    navigation: NavigationServiceMenu[] = [];
    currentNotificationsCount: number = 0;
    company: Franchise|null = null;
    isServer: boolean = false;


    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private navigationService: NavigationService,
        private notificationService: NotificationService,
        private franchiseService: FranchiseService,
    ) {
        this.isServer = isPlatformServer(this.platform);
    }

    ngOnInit(): void {
        this.navigation = this.navigationService.getNavigation();

        const notificationsCountSubscription = this.notificationService.getMessagesCount().subscribe(count => {
            this.currentNotificationsCount = count;
        });
        this.subscriptions.push(notificationsCountSubscription);

        const companySubscription = this.franchiseService.getCompanyDetails().subscribe(data => this.company = data);
        this.subscriptions.push(companySubscription);
    }

    ngAfterContentInit(): void {
        !this.isServer && setTimeout(() => {
            this.showTooltip();
        });
    }

    showTooltip() {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

        tooltipTriggerList.forEach(function (tooltipTriggerEl) {
            // @ts-ignore
            new bootstrap.Tooltip(tooltipTriggerEl);
        });
    }

    onNavItemEnter(event: Event, menuItem: HTMLElement): void {
        // @ts-ignore
        const tooltip = bootstrap.Tooltip.getInstance(menuItem);
        tooltip && tooltip?.show();
    }

    onNavItemLeave(event: Event, menuItem: HTMLElement): void {
        // @ts-ignore
        const tooltip = bootstrap.Tooltip.getInstance(menuItem);
        tooltip && tooltip?.hide();
    }

}
