<p-dialog
    [visible]="true"
    (visibleChange)="onHidePopup($event)"
    [closeOnEscape]="skippable"
    [dismissableMask]="skippable"
    [draggable]="false"
    [modal]="true"
    contentStyleClass="flex-shrink-0 overflow-visible"
    maskStyleClass="overflow-auto"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="headline"
>
    <loading [loading]="loading"></loading>

    <div class="border-bottom pb-3 mb-3" *ngIf="multilevel">
        <p-dropdown
                    [options]="categories"
                    optionValue="value"
                    optionLabel="title"
                    class="dropdown"
                    styleClass="w-100"
                    (onChange)="onCategoryChange($event)"
        >
            <ng-template let-selected pTemplate="selectedItem">
                <!-- primeng 16+ workaround for null-values -->
                <ng-container *ngIf="selected">{{ selected?.title }}</ng-container>
                <ng-container *ngIf="!selected">{{ categories?.[0]?.title }}</ng-container>
            </ng-template>
        </p-dropdown>
    </div>

    <div class="row" *ngIf="!loading && visibleItems?.length">
        <div class="col-11 col-sm-12">
            <div cdkDropList class="list" (cdkDropListDropped)="onDrop($event)">
                <div class="list-item d-block" *ngFor="let item of visibleItems; trackBy: trackItem" cdkDrag [cdkDragData]="item" cdkDragLockAxis="y">
                    <i class="fas fa-fw fa-sort me-2"></i>
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>

    <alert *ngIf="!loading && !visibleItems?.length" [message]="'Няма намерени резултати.' | transloco" type="error"></alert>

    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end align-items-center">
            <button type="button"
                *ngIf="withConfirm"
                class="btn btn-primary"
                (click)="onSubmit($event)"
                [attr.disabled]="loading || !isDirty ? true : null"
            >
                <i class="fa-solid fa-check fa-fw"></i>
                {{ 'Потвърди' | transloco }}
            </button>

            <button type="button" class="btn btn-secondary" (click)="onHidePopup($event)">
                <i class="fa-solid fa-xmark fa-fw"></i>
                {{ 'Затвори' | transloco }}
            </button>
        </div>
    </ng-template>
</p-dialog>
