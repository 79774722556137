<div class="btn-group"
    [class.me-3]="rightOffset"
    role="group"
    aria-label="Button group with nested dropdown"
    *ngIf="!dropdownOnly && actions.length && user && user.role && user.role.name && roles && (user|roleAny:roles)"
>
    <ng-container *ngFor="let action of actions | slice:0:dropdownStartFrom">
        <a  [routerLink]="action?.link" *ngIf="action?.link && user && user.role && user.role.name"
            [queryParams]="action?.queryParams"
            class="btn btn-primary"
            [class.highlight]="action?.highlight"
            [class.btn-sm]="smallButtons"
            [class.disabled]="action?.disabled"
        >
            <i [class]="(action?.icon || defaultEmptyIcon) + ' fa-fw'"></i>
            <span class="d-none d-md-inline">  {{ action?.text }} </span>
        </a>

        <button *ngIf="!action?.link && action?.file === null && user && user.role && user.role.name"
                type="button"
                (click)="onButtonClicked(action?.id ?? action?.text ?? '')"
                class="btn btn-primary"
                [class.highlight]="action?.highlight"
                [class.btn-sm]="smallButtons"
                [class.disabled]="action?.disabled"
                [attr.disabled]="action?.disabled ? true : null"
        >
            <i [class]="(action?.icon || defaultEmptyIcon) + ' fa-fw'"></i> <span class="d-none d-md-inline"> {{ action?.text }} </span>
        </button>

        <upload *ngIf="action && action?.file !== null && !action?.link && action?.text && !action?.header"
                [type]="action?.file ?? 'file'"
                [files]="1"
                [chunkSize]="30*1024"
                [label]="'Избор на файл' | transloco"
                [labelUploading]="'Качване...' | transloco"
                [disabled]="action?.disabled ?? false"
                class="btn btn-primary p-0 btn-in-group"
                (onFileStarted)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileStarted', $event)"
                (onFileUploaded)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileUploaded', $event)"
                (onFileProgress)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileProgress', $event)"
                (onFileError)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileError', $event)"
                (onFilesStarted)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFilesStarted', $event)"
                (onFilesFinished)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFilesFinished', $event)"
        >
            <ng-template itemTemplate="default" let-isButtonHover>
                <div class="dropdown-item upload-item text-start" [class.hover]="isButtonHover">
                    <i [class]="(action?.icon || defaultEmptyIcon) + ' fa-fw'"></i>
                    {{ action?.text }}
                </div>
            </ng-template>
        </upload>

    </ng-container>

    <div class="btn-group" role="group" *ngIf="(actions?.length ?? 0) > dropdownStartFrom">
        <ng-container   [ngTemplateOutlet]="dropdown"
                        [ngTemplateOutletContext]="{actions: actions | slice:dropdownStartFrom, user: user}">
        </ng-container>
    </div>
</div>

<ng-container   *ngIf="dropdownOnly && (!user || !user.role || !roles || (user|roleAny:roles))"
                [ngTemplateOutlet]="dropdown"
                [ngTemplateOutletContext]="{actions: actions, user: user}">
</ng-container>

<ng-template #dropdown  let-actions="actions" let-user="user">
    <button type="button"
            class="btn {{ dropdownClass }} dropdown-toggle"
            [class.btn-sm]="smallButtons" data-bs-toggle="dropdown" aria-expanded="false"
    >
        <i *ngIf="dropdownIcon?.length" class="fa-solid fa-fw fa-lg" [ngClass]="dropdownIcon"></i>

        {{ dropdownButtonText }}
    </button>
    <ul class="dropdown-menu">
        <ng-container *ngFor="let action of actions">
            <li *ngIf="!action?.hide">
                <a [routerLink]="action?.link" *ngIf="action?.link"
                    [queryParams]="action?.queryParams"
                    class="dropdown-item btn btn-primary btn-sm rounded-0"
                    [class.disabled]="action?.disabled"
                >
                    <i [class]="(action?.icon || defaultEmptyIcon) + ' fa-fw'"></i>
                    {{ action?.text }}
                </a>

                <button *ngIf="action && action?.file === null && !action?.link && action?.text && !action?.header"
                        class="dropdown-item btn btn-primary btn-sm rounded-0"
                        (click)="onButtonClicked(action?.id ?? action?.text)"
                        [class.disabled]="action?.disabled"
                        [attr.disabled]="action?.disabled ? true : null"
                        type="button"

                >
                    <i [class]="(action?.icon || defaultEmptyIcon) + ' fa-fw'"></i>

                    {{ action?.text }}
                </button>

                <upload *ngIf="action && action?.file !== null && !action?.link && action?.text && !action?.header"
                        [type]="action?.file"
                        [files]="1"
                        [chunkSize]="30*1024"
                        [label]="'Избор на файл' | transloco"
                        [labelUploading]="'Качване...' | transloco"
                        [disabled]="action?.disabled"
                        class="btn btn-default p-0 w-100 rounded-0 border-0"
                        (onFileStarted)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileStarted', $event)"
                        (onFileUploaded)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileUploaded', $event)"
                        (onFileProgress)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileProgress', $event)"
                        (onFileError)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFileError', $event)"
                        (onFilesStarted)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFilesStarted', $event)"
                        (onFilesFinished)="onUploadHnalde(action?.id ?? action?.text ?? '', 'onFilesFinished', $event)"
                >
                    <ng-template itemTemplate="default" let-isButtonHover>
                        <div class="dropdown-item upload-item text-start" [class.hover]="isButtonHover">
                            <i [class]="(action?.icon || defaultEmptyIcon) + ' fa-fw'"></i>
                            {{ action?.text }}
                        </div>
                    </ng-template>
                </upload>

                <hr *ngIf="action?.divider" class="dropdown-divider">

                <span *ngIf="action?.header" class="dropdown-header">
                    {{ action?.text }}
                </span>
            </li>
        </ng-container>
    </ul>
</ng-template>


