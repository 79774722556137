<page-wrap  [headline]="headline"
            [subheading]="franchiseId && (user | roleAny:'admin') ? (franchiseCourse?.franchise?.name ?? '') : ''"
            [navigation]="
                (user | roleAny:'admin':'marketing') && !franchiseId ? [
                    {
                        title: 'Курсове' | transloco,
                        link: '/courses'
                    }, {
                        title: course?.title ?? '',
                    }
                ] : (user | roleAny:'admin':'marketing') && franchiseId ? [
                    {
                        title: 'Партньори' | transloco,
                        link: '/partners'
                    }, {
                        title: franchiseCourse?.franchise?.name ?? '',
                        link: '/partners/' + franchiseId
                    }, {
                        title: ('Закупен курс \{{course}\}'|transloco:{course:course?.title}),
                    }
                ] : [
                    {
                        title: 'Компания' | transloco,
                        link: '/company'
                    }, {
                        title: 'Закупени курсове' | transloco,
                        link: '/courses/list'
                    }, {
                        title: ('Преглед на \{{name}\}' | transloco:{name:course?.title})
                    }
                ]"
>
    <loading [loading]="loading" [hasData]="!!course?.id"></loading>

    <ng-template section="sidebar"></ng-template>

    <div class="d-none">{{ 'Изтрий курс' | transloco }}</div>
    <ng-template section="actions">
        <menu-actions   *ngIf="course?.id && !franchiseId"
                        [actions]="[
                            {
                                text: ('Редакция' | transloco),
                                link: '/courses/' + course?.id + '/edit',
                                icon: 'fa-solid fa-pen-to-square',
                            },
                            {
                                text: ('Изтрий курс' | transloco),
                                icon: 'fa-solid fa-trash-can',
                                id:'delete_course',
                                hide: !!tabs?.groups?.entity?.data?.length,
                            },
                        ]"
                        [roles]="['admin']"
                        (buttonClicked)="onButtonClicked($event)"
        ></menu-actions>

        <menu-actions   *ngIf="(user | roleAny:'partner') || franchiseId"
                        [actions]="[
                            {
                                text: ('Редакция' | transloco),
                                link: (user | roleAny:'partner')
                                    ? '/courses/' + courseId + '/edit'
                                    : '/partners/' + franchiseId + '/courses/' + courseId + '/edit'
                                ,
                                icon: 'fa-solid fa-pen-to-square',
                            },
                            { text: ('Курс' | transloco), icon: 'fa-book fa-fw fa-solid', link: '/courses/' + course?.id, hide: !(user|roleAny:'admin':'marketing') },
                        ]"
                        [roles]="['partner', 'admin']"
        ></menu-actions>

    </ng-template>

    <div class="row" *ngIf="course?.id">
        <card-course class="col-xxl-11 col-12" [course]="course"></card-course>
    </div>

    <div
         *ngIf="course?.id"
         class="my-5"
         infinite-scroll
         [infiniteScrollDistance]="2"
         (scrolled)="onScrollPage($event)"
    >
        <tabs paramType="id" class="mt-5" variant="orange">
            <tab id="modules"
                 [label]="('Модули' | transloco) + (course?.modules?.length
                    ? ' (' + course?.modules?.length + ')'
                    : '')"
            >

                <div class="mx-2" *ngIf="(user | roleAny: 'admin') && !franchiseId">
                    <div class="col-12 mt-4 mb-3 text-end">
                        <div class="d-none">
                            {{ 'Нов модул' | transloco }}
                            {{ 'Копирай модули от курс' | transloco }}
                            {{ 'Нов тест' | transloco }}
                        </div>

                        <menu-actions   [actions]="[
                                            { text: ('Нов модул' | transloco), icon: 'fa-solid fa-plus fa-fw', id:'add_module'},
                                            { text: ('Копирай модули от курс' | transloco), icon: 'fa-regular fa-copy fa-fw', id:'copy_from_course'},
                                            { text: ('Нов тест' | transloco), icon: 'fa-solid fa-plus fa-fw', link: '/courses/' + courseId + '/exams/add'},
                                        ]"
                                        [roles]="['admin','partner']"
                                        (buttonClicked)="onButtonClicked($event)"
                                        [smallButtons]="false"
                                        [rightOffset]="false"
                        ></menu-actions>
                    </div>
                </div>

                <div class="col-12 mt-4" [class.mb-2]="(user | roleAny: 'admin')">
                    <div *ngIf="!tabs?.modules?.entity?.data?.length" class="alert alert-warning" [class.mt-3]="(user | roleAny: 'admin')">
                        {{ 'Няма налични модули.' | transloco }}
                    </div>
                </div>

                <div class="col-12">
                    <div class="modules shadow-none">
                        <ng-container  [ngTemplateOutlet]="itemsWrapper"
                                       [ngTemplateOutletContext]="{
                                            list: tabs?.modules?.entity?.data
                                        }"
                        ></ng-container>
                    </div>
                </div>
            </tab>

            <tab
                id="topics"
                [label]="('Разглеждани теми' | transloco) + (course?.topics?.length
                    ? ' (' + course?.topics?.length + ')'
                    : '')"
            >
                <div class="col-12 mt-4 mb-5">
                    <div *ngIf="!tabs?.topics?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма налични разглеждани теми.' | transloco }}
                    </div>

                    <div class="modules shadow-none">
                        <ng-container *ngFor="let item of tabs?.topics?.entity?.data; let index = index">

                            <div class="d-flex align-items-center item"
                                [class.disable]="item?.status !== 'active'"
                            >
                                <div class="col-12">
                                    <div class="module-card d-flex flex-md-row flex-column justify-content-start p-3">

                                        <h3 class="mb-0 col-12 col-md-3 pe-2 order-1 order-md-0 d-flex align-items-center justify-content-center d-md-block">
                                            <img src="/assets/img/icons/bubble.svg" width="36" height="36" alt="bubble">
                                            {{ ('№\{{sequence}\}' | transloco:{sequence: index + 1}) }}
                                        </h3>

                                        <p class="mb-0 align-self-center col order-2 order-md-1 d-flex">
                                            <span class="module-title me-3 me-md-0">
                                                {{ item?.title }}
                                            </span>
                                        </p>

                                        <div class="col-2 col-md-auto order-1 order-md-3">
                                            {{ '\{{hours}\} часа' | transloco:{hours: item?.hours ?? 0} }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </tab>

            <tab id="groups"
                 [label]="('Учебни групи' | transloco) + ((tabs.groups.entity.data?.length ?? 0) > 0 ? ' (' + tabs.groups.entity.data?.length + ')' : '') "
            >
                <div class="mx-2">
                    <div class="col-12 mt-4 mb-3 text-end">
                        <menu-actions   data-bs-boundary="body"
                                        [actions]="[{
                                                link: '/groups/add',
                                                queryParams: {
                                                    course: course?.id,
                                                    franchise: (user | roleAny: 'admin') && (franchiseId ?? 0) > 0 ? franchiseId : null
                                                },
                                                text: ('Нова група' | transloco),
                                                icon: 'fa-plus fa-solid',
                                        }]"
                                        [roles]="['admin', 'partner']"
                                        (buttonClicked)="onButtonClicked($event)"
                                        [smallButtons]="false"
                                        [rightOffset]="false"
                        ></menu-actions>
                    </div>
                </div>

                <div class="col-12 mt-4 mb-5">
                    <div class="d-none">
                        {{ 'Нова група' | transloco }}
                    </div>

                    <div *ngIf="!tabs?.groups?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма налични учебни групи.' | transloco }}
                    </div>

                    <div [class.mt-2]="tabs?.groups?.entity?.data?.length">
                        <div class="row mx-0 my-3 align-items-stretch" *ngIf="tabs?.groups?.entity?.data?.length">
                            <div class="col-lg-6 mb-2 mb-md-3" *ngFor="let group of tabs?.groups?.entity?.data; let index = index; trackBy: trackBy">
                                <card-group-small   [group]="group"
                                                    [user]="user"
                                                    [withEdit]="!!(user && (user | roleAny: 'partner':'admin'))"
                                                    [withRemove]="true"
                                                    (onDeleted)="onGroupDeleted()"
                                ></card-group-small>
                            </div>
                        </div>

                    </div>
                </div>
            </tab>

            <tab
                *ngIf="franchiseCourse?.id"
                id="relations"
                [label]="('Свързани курсове' | transloco) + ((
                    (franchiseCourse?.relations?.program?.length ?? 0)
                    + (franchiseCourse?.relations?.suggestion?.length ?? 0)
                    + (franchiseCourse?.relations?.other?.length ?? 0)
                ) > 0
                    ? ' (' + (
                        (franchiseCourse?.relations?.program?.length ?? 0)
                        + (franchiseCourse?.relations?.suggestion?.length ?? 0)
                        + (franchiseCourse?.relations?.other?.length ?? 0)
                    ) + ')'
                    : '')"
            >
                <subtab *ngFor="let subtab of relationSubTabs"
                    [id]="subtab.name"
                    [label]="subtab.label + (franchiseCourse?.relations?.[subtab.name]?.length ? ' (' + franchiseCourse?.relations?.[subtab.name]?.length + ')' : '')"
                >
                    <div class="mx-2">
                        <div class="col-12 mt-4 mb-3 text-end">
                            <menu-actions   data-bs-boundary="body"
                                            [actions]="[{
                                                id: 'relations_add',
                                                text: ('Нова връзка' | transloco),
                                                icon: 'fa-plus fa-solid',
                                            }, {
                                                id: 'relations_sort',
                                                text: ('Подреждане' | transloco),
                                                icon: 'fas fa-sort-alpha-down fa-fw',
                                            }]"
                                            [roles]="['admin', 'partner']"
                                            (buttonClicked)="onButtonClicked($event, subtab.name)"
                                            [smallButtons]="false"
                                            [rightOffset]="false"
                            ></menu-actions>

                            <ng-container *ngIf="false">
                                {{ ('Нова връзка' | transloco) }}
                                {{ ('Подреждане' | transloco) }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-12 mt-4 mb-5">
                        <div *ngIf="!franchiseCourse?.relations?.[subtab.name]?.length" class="alert alert-warning">
                            {{ 'Няма налични свързани курсове.' | transloco }}
                        </div>

                        <div class="modules shadow-none">
                            <ng-container *ngFor="let relation of franchiseCourse?.relations?.[subtab.name]; let index = index">

                                <div class="d-flex align-items-center item"
                                    [class.disable]="relation?.status !== 'active' || relation?.course?.status !== 'active'"
                                >
                                    <a  class="col-12"
                                        [routerLink]="(user | roleAny: 'admin')
                                            ? '/partners/' + franchiseId + '/courses/' + relation?.id
                                            : '/courses/' + relation?.id"
                                    >
                                        <div class="module-card d-flex flex-md-row flex-column justify-content-start p-3">

                                            <h3 class="mb-0 col-12 col-md-3 pe-2 order-1 order-md-0 d-flex align-items-center justify-content-center d-md-block">
                                                <img src="/assets/img/icons/bubble.svg" width="36" height="36" alt="bubble">
                                                {{ ('№\{{sequence}\}' | transloco:{sequence: index + 1}) }}
                                            </h3>

                                            <p class="mb-0 align-self-center col order-2 order-md-1 d-flex">
                                                <span class="module-title me-3 me-md-0">

                                                    {{ relation?.course?.title ?? ('Курс' | transloco) }}
                                                    <ng-container *ngIf="relation?.title?.length && relation?.course?.title !== relation?.title">
                                                        <br />
                                                        <small>({{ relation?.title ?? '' }})</small>
                                                    </ng-container>
                                                </span>
                                            </p>

                                            <div class="col-2 col-md-auto order-0 order-md-3">
                                                <div class="d-flex align-items-center text-end text-md-start order-0 order-md-3">
                                                    <button class="btn ms-auto" [id]="'dropdownMenuButton' + index" (click)="toggleOverlay($event, overlayPanelModules)">
                                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                                    </button>
                                                    <p-overlayPanel #overlayPanelModules [dismissable]="true" appendTo="body" showTransitionOptions="0.01s" hideTransitionOptions="0.01s">
                                                        <ng-template pTemplate="content">
                                                            <div class="custom-dropdown-item" (click)="onRelationDelete($event, subtab.name, relation)">
                                                                <i class="fa-regular fa-trash-can text-danger fa-fw"></i>
                                                                {{ 'Премахване на курс' | transloco }}
                                                            </div>
                                                        </ng-template>
                                                </p-overlayPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </subtab>
            </tab>
        </tabs>
    </div>
</page-wrap>

<ng-template #itemsWrapper let-list="list">
    <ng-container *ngFor="let module of list; let index = index">
        <div class="d-flex align-items-center item"
             [class.disable]="module?.status !== 'active'"
        >
            <a *ngIf="module?.status === 'active' || (user | roleAny:'admin':'partner')"
                class="col-12"
                [routerLink]="(module?.test?.id && !module?.test?.is_midterm) && (user | roleAny:'student')
                    ? ('/courses/' + course.id + '/modules/' + module?.id + '/exam')
                    : ('/courses/' + course.id + '/modules/' + module?.id)"
            >
                <ng-container [ngTemplateOutlet]="item"
                              [ngTemplateOutletContext]="{
                                    item: module,
                                    index: index
                              }"
                ></ng-container>
            </a>
            <div *ngIf="module?.status !== 'active' && !(user | roleAny:'admin':'partner')" class="col-12">
                <ng-container [ngTemplateOutlet]="item"
                              [ngTemplateOutletContext]="{
                                    item: module,
                                    index: index
                              }"
                ></ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #item let-item="item" let-index="index">
    <div class="module-card d-flex flex-md-row flex-column justify-content-start p-3">
        <!-- module ID / sequence -->
        <h3 class="mb-0 col-12 col-md-3 pe-2 order-1 order-md-0 d-flex align-items-center justify-content-center d-md-block">
            <img src="/assets/img/icons/bubble.svg" width="36" height="36" alt="bubble">
            {{ ('Модул' | transloco) + '&nbsp;' + item?.number }}
        </h3>

        <!-- module title -->
        <p class="mb-0 align-self-center col order-2 order-md-1 d-flex">
            <span class="module-title me-3 me-md-0">
                {{ item?.title }}
            </span>

            <small *ngIf="item?.test && !(user | roleAny:'student')"
                    class="badge bg-warning text-dark ms-auto me-0 me-md-3 align-self-center text-capitalize"
                    [class.text-muted]="item?.test?.status !== 'active'"
                    [title]="item?.test?.status === 'active' ? '' : ('Неактивен' | transloco)"
            >
                <i class="fa-regular fa-eye-slash fa-fw" *ngIf="item?.test?.status !== 'active'"></i>
                {{item?.test?.type?.description}}
            </small>
        </p>

        <!-- change status-->
        <div class="order-md-2 align-items-center d-flex mx-2" *ngIf="(user | roleAny: 'admin') && !franchiseId"
            (click)="$event.stopPropagation(); $event.preventDefault()"
        >
            <label class="form-check form-switch d-inline-block mb-0 p-2"
                    [title]="'Смени видимостта на модула' | transloco"
                    (click)="onModuleChangeStatus($event, item) "
            >
                <input  class="form-check-input mx-0 mt-0" [class.checked]="item.status === 'active'"
                        type="checkbox"
                        [ngModel]="item.status === 'active'"
                        [checked]="item.status === 'active' ? true : null"
                        (click)="onModuleChangeStatus($event, item) "
                />
            </label>
        </div>

        <!-- dropdown -->
        <div class="col-2 col-md-auto order-0 order-md-3" *ngIf="(user | roleAny: 'admin') && !franchiseId">
            <div class="d-flex align-items-center text-end text-md-start order-0 order-md-3">
                <button class="btn ms-auto" [id]="'dropdownMenuButton' + index" (click)="toggleOverlay($event, overlayPanelModules)">
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <p-overlayPanel #overlayPanelModules [dismissable]="true" appendTo="body" showTransitionOptions="0.01s" hideTransitionOptions="0.01s">
                    <ng-template pTemplate="content">
                        <ng-container *ngIf="item?.test?.id && (item?.test?.type?.title != 'final' || (user | roleAny: 'admin'))">
                            <a
                                class="custom-dropdown-item "
                                [routerLink]="'/courses/' + course?.id + '/exams/edit/' + item?.test?.id">
                                <i class="fa-solid fa-list-ol fa-fw"></i>
                                {{ 'Редакция на тест' | transloco }}
                            </a>
                            <div class="dropdown-divider"></div>
                        </ng-container>

                        <ng-container *ngIf="!item?.test?.id">
                            <div class="custom-dropdown-item">
                                <a [routerLink]="'/courses/' + course?.id + '/exams/add/' + item?.id" class="dropdown-item">
                                    <i class="fa-solid fa-plus fa-fw"></i>
                                    {{ 'Добавяне на тест' | transloco }}
                                </a>
                            </div>
                            <div><hr class="dropdown-divider"></div>
                        </ng-container>

                        <div class="custom-dropdown-item" (click)="onModuleEdit($event, item)">
                            <i class="fa-solid fa-pen-to-square fa-fw"></i>
                            {{ 'Редакция на модул' | transloco }}

                        </div>

                        <div><hr class="dropdown-divider"></div>

                        <div class="custom-dropdown-item" (click)="onModuleDelete($event, item)">
                            <i class="fa-regular fa-trash-can text-danger fa-fw"></i>
                            {{ 'Премахване на модул' | transloco }}
                        </div>


                    </ng-template>
              </p-overlayPanel>
            </div>
        </div>
    </div>
</ng-template>

<modal-course-module-form *ngIf="showModuleForm"
                            (onAction)="onModuleEditAction($event)"
                            [course]="course"
                            [module]="module ?? null"
                            [mode]="module?.id ? 'edit' : 'add'"
></modal-course-module-form>

<modal-confirm *ngIf="showModuleRemoveConfirm"
                [headline]="'Премахване на модул?' | transloco"
                [body]="'Желаете ли да премахнете този модул?' | transloco"
                [skippable]="true"
                (onAction)="onModuleDeleteConfirmed($event)"
></modal-confirm>

<modal-confirm *ngIf="showCourseDeleteConfirm"
                [headline]="'Изтриване на курс?' | transloco"
                [body]="'Желаете ли да изтриете курса?' | transloco"
                [skippable]="true"
                (onAction)="onCourseDeleteConfirmed($event)"
></modal-confirm>

<modal-items-chooser *ngIf="showCopyModulesDialog"
                     [headline]="'Изберете курс' | transloco"
                     [items]="coursesList"
                     [(loading)]="loading"
                     [multipleOptions]="false"
                     (onSelect)="onSelectedCourseToCopy($event)"
                     (onAction)="onCopyModulesAction($event)"
                     (onMoreItems)="onCoursesListMore($event)"
></modal-items-chooser>

<modal-sort-items *ngIf="showSortModal"
                  [items]="sortableItems"
                  [withConfirm]="true"
                  [multilevel]="false"
                  [loading]="loading"
                  (sortAll)="onSortItems($event)"
                  (close)="showSortModal = false"
></modal-sort-items>

<modal-confirm *ngIf="showRelationRemoveConfirm"
                [headline]="'Премахване на курс?' | transloco"
                [body]="'Желаете ли да премахнете този курс от списъка?' | transloco"
                [skippable]="true"
                (onAction)="onRelationDeleteConfirmed($event)"
></modal-confirm>

<modal-items-chooser *ngIf="showCopySectionsDialog"
                     [headline]="'Изберете курсове' | transloco"
                     [items]="franchiseCoursesList"
                     (onSelect)="onSelectSectionsToCopy($event)"
                     (onAction)="showCopySectionsDialog = false"
                     (onMoreItems)="onFranchiseCourseListMore($event)"
></modal-items-chooser>
