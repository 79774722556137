import * as moment from "moment";
import { Franchise } from "../franchise.model";
import { User } from "../user/user.model";

export class GlobalMessage {
    id?: number;
    description?: string;
    start_date?: string|Date;
    end_date?: string|Date;
    status?: ('active'|'inactive');
    franchise?: Franchise;
    sender?: User;

    static fromJson(fields: any): GlobalMessage {
        let item = new GlobalMessage();
        Object.assign(item, fields);

        if (fields.start_date) {
            item.start_date = GlobalMessage.convertUTCDateToLocal(fields.start_date);
        }

        if (fields.end_date) {
            item.end_date = GlobalMessage.convertUTCDateToLocal(fields.end_date);
        }

        item.franchise = fields.franchise ? Franchise.fromJson(fields.franchise) : undefined;
        item.sender = fields.sender ? User.fromJson(fields.sender) : undefined;

        return item;
    }

    private static convertUTCDateToLocal(utcDate: string): Date {
        let date = new Date(utcDate + 'Z');
        return date;
    }
}
