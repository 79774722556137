import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

import { Course } from 'src/app/shared/models/course.model';
import { Module } from 'src/app/shared/models/module.model';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CourseService, CourseServiceIndex } from 'src/app/shared/services/course.service';
import { FranchiseService, FranchiseServiceCourseIndex } from 'src/app/shared/services/franchise.service';
import { UserServicePaginate } from 'src/app/shared/services/user.service';
import { ListingTableComponentColumn } from 'src/app/shared/components/listing-table/listing-table.component';
import { GroupCourse } from 'src/app/shared/models/user/user-group-course.model';
import { GroupService, GroupServiceGroupFilter } from 'src/app/shared/services/group.service';
import { CourseTopic } from 'src/app/shared/models/course/course-topic.model';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ModalItemsChooseComponentItem } from 'src/app/shared/components/modal-items-chooser/modal-items-chooser.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { FranchiseCourse } from '../../models/franchise-course.model';
import { ModalSortItemsComponentItem } from '../modal-sort-items/modal-sort-items.component';

type Tab = (
    'groups'
    | 'modules'
    | 'topics'
);

type RelationTypes = (
    'program'
    |'suggestion'
    |'other'
);

interface TabList {
    entity: Paginate<any>;
    filter: GroupServiceGroupFilter;
    columns?: ListingTableComponentColumn[];
};

declare var bootstrap: any;

@Component({
    selector: 'page-franchise-course-view',
    templateUrl: './page-franchise-course-view.component.html',
    styleUrls: ['./page-franchise-course-view.component.scss']
})
export class PageFranchiseCourseViewComponent implements OnInit, OnDestroy{
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;

    @ViewChildren('dropdownActions') dropdownActions?: QueryList<ElementRef>;
    @ViewChildren(OverlayPanel) overlayPanels!: QueryList<OverlayPanel>;

    protected subscriptions: Subscription[] = [];
    protected loading: boolean = false;
    protected innerLoadingQueue: number = 0;

    protected readonly tabsDefaultFilter: UserServicePaginate = {
        limit: 10,
        page: 2,
    };

    courseId: number|null = null;
    franchiseId: number|null = null;
    headline: string|null = null;

    showModuleForm: boolean = false;
    showModuleRemoveConfirm: boolean = false;
    showCourseDeleteConfirm: boolean = false;
    showCopyModulesDialog: boolean = false;
    showSortModal: boolean = false;
    showCopySectionsDialog: boolean = false;
    showRelationRemoveConfirm: boolean = false;

    courses!: Paginate<Course>;
    coursesList: ModalItemsChooseComponentItem[] = [];
    coursesFilter: CourseServiceIndex = {
        page: 1,
        sort: 'title',
        limit: this.PAGINATION_LIMIT,
        q: '',
        filter: {
            modules_count: {
                '>=': 1
            }
        }
    };
    sortableItems: ModalSortItemsComponentItem[] = [];
    relationType: RelationTypes = 'program';

    relationFranchiseCourse?: FranchiseCourse;
    franchiseCourses!: Paginate<FranchiseCourse>|null;
    franchiseCoursesList: ModalItemsChooseComponentItem[] = [];
    franchiseCoursesFilter: FranchiseServiceCourseIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        q: '',
        include: 'sections.items',
    };

    module: Module|null = null;

    id: number|null = null;
    user: User = new User;

    course: Course = new Course();
    franchiseCourse!: FranchiseCourse;
    showExport: boolean = false;
    testId: number = 0;

    modulesLength: number = 0;

    currentTab: Tab = 'groups';
    tabs: {[key in Tab]: TabList} = {
        groups: {
            entity: new Paginate<GroupCourse>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
        modules: {
            entity: new Paginate<Module>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
        topics: {
            entity: new Paginate<CourseTopic>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
    };
    relationSubTabs: Array<{label: string, name: RelationTypes}> = [
        {
            label: translate('Програма'),
            name: 'program'
        }, {
            label: translate('Предложения'),
            name: 'suggestion'
        }, {
            label: translate('Други'),
            name: 'other'
        }
    ];

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private franchiseService: FranchiseService,
        private courseService: CourseService,
        private groupService: GroupService,
        private locationService: Location,
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.user = this.authService?.getUser() ?? this.user;

        this.getId();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    protected getId(): void {
        const subscription = this.route.params.subscribe((data: any) => {

            if (this.user?.can(['admin', 'marketing'])) {
                this.courseId = +(data['courseId'] ?? 0);
                this.franchiseId = +(data['id'] ?? 0);

                if (!this.courseId && this.franchiseId) {
                    this.courseId = this.franchiseId;
                    this.franchiseId = null;
                }
            } else {
                this.courseId = +(data['id'] ?? 0);
                this.franchiseId = +(this.user?.franchise?.id ?? 0);
            }

            this.getItem(this.courseId);
        });
        this.subscriptions.push(subscription);
    }

    protected getItem(id: number): void {
        this.loadingQueue++;

        const getCourseDetails: (id: number) => Promise<Course> = (id: number) => {
            return new Promise((resolve, rejected) => {
                const subscription = this.courseService.getItem(id, {include: ['modules', 'spotlights', 'topics', 'design']}).subscribe({
                    next: response => {
                        this.loadingQueue--;

                        this.course = response.data;
                        this.headline = this.course.title ?? '';

                        this.modulesLength = this.course.modules?.length ?? 0;
                        this.tabs['modules'].entity?.setData(this.course?.modules ?? []);
                        this.tabs['topics'].entity?.setData(this.course?.topics ?? []);

                        resolve(this.course);
                    }, error: error => {
                        this.loadingQueue--;

                        this.messageService.add({
                            severity: 'error',
                            summary: translate('Грешка!'),
                            detail: translate('Записът не е намерен!')
                        });
                        this.locationService.back();

                        rejected(error);
                    }
                });
                this.subscriptions.push(subscription);
            });
        }

        if (this.user?.can(['partner']) || (this.franchiseId ?? 0) > 0) {
            this.franchiseService.getCourse(this.franchiseId ?? 0, id).subscribe({
                next: response => {
                    this.franchiseCourse = response?.data;

                    getCourseDetails(response.data.course?.id ?? 0).then(course => {
                        this.tabs['groups'].filter.course = this.franchiseCourse?.course?.id ?? id;
                        this.tabs['groups'].filter.franchise = this.franchiseId;
                        this.getAdditionalData(this.currentTab, false);
                    });
                },
                error: error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: translate('Записът не е намерен!')
                    });
                    this.locationService.back();
                }
            });
        } else {
            getCourseDetails(id).then(course => {
                this.getAdditionalData(this.currentTab, false);
            });
        }
    }

    protected getAdditionalData(tab: Tab, appendData: boolean = false): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.tabs[tab]?.entity?.data?.length) {
            if (this.tabs[tab]?.entity?.reachEnd()) {
                return;
            }
            this.tabs[tab].filter.page = this.tabs[tab]?.entity?.nextPage();
        } else if (!this.tabs[tab]?.entity?.data?.length) {
            this.tabs[tab].filter.page = 1;
        }

        const action: {[key in Tab]?: Function} = {
            groups: this.groupService.getList,
        };

        if (!action[tab]) {
            return;
        }

        this.tabs.groups.filter.course = this.franchiseCourse?.course?.id ?? this.courseId;

        this.loadingQueue++;

        const subscription = action[tab]?.call(this.groupService, this.tabs[tab].filter).subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                if (appendData && this.tabs[tab].entity) {
                    data.data = [
                        ...this.tabs[tab]?.entity?.data ?? [],
                        ...data?.data ?? [],
                    ];
                }

                this.tabs[tab].entity = data;
            }, error: (error: any) => {
                this.loadingQueue--;
            }
        });

        this.subscriptions.push(subscription);
    }

    onButtonClicked(buttonId: any, relationType: any = null) {
        if(buttonId === 'add_module') {
            this.showModuleForm = true;
            this.module = null;
        } else if (buttonId === 'copy_from_course') {
            this.coursesFilter.page = 1;
            this.coursesFilter.q = '';

            this.getCoursesList();
            this.showCopyModulesDialog = true;
        } else if (buttonId === 'delete_course') {
            this.onCourseDelete();
        } else if (buttonId === 'relations_add') {
            this.relationType = relationType;
            this.franchiseCoursesList = [];
            this.franchiseCoursesFilter = {
                q: '',
                page: 1,
                limit: this.PAGINATION_LIMIT,
                include: 'sections.items',
            };
            this.getFranchiseCourses();
            this.showCopySectionsDialog = true;

        } else if (buttonId === 'relations_sort') {
            this.relationType = relationType;
            this.sortableItems = this.franchiseCourse?.relations?.[this.relationType]?.map(item => {
                return {
                    title: item?.title ?? item?.course?.title ?? translate('Курс'),
                    value: item?.id,
                };
            }) ?? [];
            this.showSortModal = true;
        }
    }

    onModuleEdit(event?: Event, module?: Module): void {
        event?.preventDefault();
        event?.stopPropagation();

        this.showModuleForm = true;
        this.module = module ?? null;
    }

    onModuleEditAction(action: string): void {
        this.showModuleForm = false;
        this.module = null;

        if (action !== 'close') {
            this.getItem(this.course?.id ?? 0);
        }
    }

    onModuleDelete(event?: Event, module?: Module): void {
        event?.preventDefault();
        event?.stopPropagation();

        this.module = module ?? null;
        this.showModuleRemoveConfirm = true;
        this.showModuleForm = false;
    }

    onModuleDeleteConfirmed(action: string): void {
        this.showModuleRemoveConfirm = false;

        if (action === 'yes') {
            this.loadingQueue++;
            const subscription = this.courseService.deleteModule(this.course.id ?? 0, this.module?.id ?? 0).subscribe({
                next: response => {
                    this.loadingQueue--;

                    this.messageService.add({
                        severity: 'success',
                        detail: translate('Успешно премахнат модул!'),
                    });

                    this.getItem(this.course?.id ?? 0);
                    this.module = null;
                }, error: error => {
                    this.loadingQueue--;
                }
            });
            this.subscriptions.push(subscription);
        }
    }

    onCourseDelete(event?: Event): void {
        event?.preventDefault();
        event?.stopPropagation();
        this.showCourseDeleteConfirm = true;
    }

    onCourseDeleteConfirmed(action: string): void {
        this.showCourseDeleteConfirm = false;

        if (action === 'yes') {
            this.loadingQueue++;
            const subscription = this.courseService.delete(this.course.id ?? 0).subscribe({
                next: response => {
                    this.loadingQueue--;

                    this.messageService.add({
                        severity: 'success',
                        detail: translate('Успешно изтрит курс!'),
                    });

                    this.locationService.back();

                }, error: error => {
                    this.loadingQueue--;
                    this.messageService.add({
                        detail: translate('Възникна грешка при изтриването на курс!'),
                        severity: 'error'
                    });
                }
            });
            this.subscriptions.push(subscription);
        }
    }

    onModuleChangeStatus(event: Event | MouseEvent, item: Module): void {
        event?.stopPropagation();
        event?.preventDefault();

        try {
            item.status = item.toggleStatus();

            const subscription = this.courseService.updateModule(this.course?.id ?? 0, item?.id ?? 0, {
                status: item.status,
            }).subscribe({
                next: data => {
                    (event.target as HTMLInputElement)['checked'] = item.status === 'active';
                },
                error: error => {
                    item.status = item.toggleStatus();
                    this.messageService.add({
                        severity: 'error',
                        detail: translate('Възникна грешка при обновяването на модула!'),
                    });
                }
            });
            this.subscriptions.push(subscription);

        } catch (error:any) {
            this.messageService.add({
                severity: 'error',
                detail: translate(`Възникна грешка при промяна на статуса!`),
            });
        }
    }

    onAction(event?: {action?: string, paid?: boolean}): void {
        if (event?.action === 'close') {
            this.showExport = false;
        }
    }

    onScrollPage(event: any): void {
        this.getAdditionalData(this.currentTab, true);
    }

    trackBy(index: number, item: any) {
        return item?.id ?? 0;
    }

    onDropdownButton(event: Event, currentButton: HTMLElement): void {
        event?.stopPropagation();

        this.dropdownActions?.forEach(element => {
            if (!element.nativeElement?.isEqualNode(currentButton)) {
                const dropdown = bootstrap.Dropdown.getInstance(element?.nativeElement);
                dropdown && dropdown.hide();
            }
        });
    }

    onGroupDeleted(): void {
        this.getAdditionalData('groups');
    }

    toggleOverlay(event: Event, overlayPanel: OverlayPanel) {
        event.preventDefault();
        event.stopPropagation();
        this.overlayPanels.forEach(panel => {
            if (panel !== overlayPanel) {
                panel.hide();
            }
        });
        overlayPanel.toggle(event);
    }

    onCoursesListMore(additional: {q: string, reset: boolean}): void {
        if (additional.reset) {
            this.coursesFilter.page = 1;
        }

        this.coursesFilter.q = additional.q;
        this.getCoursesList(!additional.reset);
    }

    onCopyModulesAction(event: {action: string}) {
        if (event?.action === 'close') {
            this.showCopyModulesDialog = false;
        }
    }

    onSelectedCourseToCopy(selected?: number[]): void {
        this.loadingQueue++;
        const subscription = this.courseService.copyModules(this.course?.id ?? 0, selected?.[0] ?? 0).subscribe({
            next: response => {
                this.loadingQueue--;
                this.showCopyModulesDialog = false;
                this.getItem(this.courseId ?? 0);
            },
            error: error => {
                this.loadingQueue--;
                this.messageService.add({
                    detail: translate('Възникна грешка при копиране модулите от курс!'),
                    severity: 'error'
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getCoursesList(appendData?: boolean): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.courses?.data?.length) {
            if (this.courses?.reachEnd()) {
                return;
            }
            this.coursesFilter.page = this.courses?.nextPage();
        } else if (!this.courses?.data?.length) {
            this.coursesFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.courseService.getList(this.coursesFilter).subscribe({
            next: response => {
                this.loadingQueue--;

                if (appendData && this.courses) {
                    response.data = [
                        ...this.courses?.data ?? [],
                        ...response?.data ?? [],
                    ];
                }

                this.courses = response;
                this.coursesList = this.courses?.data?.map(item => {
                    return {
                        value: item.id,
                        label: item.title,
                        subtitle: [
                            moment(item.created).format(translate('L г.')),
                            item.status !== 'active' ? translate('Неактивен курс') : null,
                        ].filter(item => item).join(' – '),
                        description:
                            item.id === this.course?.id
                                ? translate('Текущ курс')
                                : translate('{{n}} модули', {n: item.modules_count}),
                        disabled: item.id === this.course?.id || !item.modules_count,
                    } as ModalItemsChooseComponentItem;
                })?.slice() ?? [];
            },
            error: error => {
                this.loadingQueue--;
                this.coursesList = [];
            }
        });
        this.subscriptions.push(subscription);

    }

    updateRelations(type: RelationTypes, items: number[], successMessage: string, failMessage: string): void {
        const subscription = this.franchiseService.updateCourse(this.franchiseId ?? 0, this.franchiseCourse?.id ?? 0, {
            relations: {
                [type]: items,
            }
        }).subscribe({
            next: response => {
                this.franchiseCourse = response?.data;

                this.messageService.add({
                    severity: 'success',
                    detail: successMessage,
                });
            },
            error: error => {
                this.messageService.add({
                    severity: 'error',
                    detail: failMessage,
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    onSortItems({parent, items}: {parent?: ModalSortItemsComponentItem|null, items: ModalSortItemsComponentItem[]}): void {
        this.showSortModal = false;

        this.updateRelations(this.relationType,
            items?.map(item => item.value),
            translate('Успешно сортирани елементи.'),
            translate('Възникна грешка при преподреждане на елементите!')
        );
    }

    onRelationDelete(event?: Event, relationType?: any, course?: FranchiseCourse): void {
        event?.preventDefault();
        event?.stopPropagation();

        this.relationType = relationType;
        this.relationFranchiseCourse = course;
        this.showRelationRemoveConfirm = true;
        this.showModuleForm = false;
    }

    onRelationDeleteConfirmed(action: string): void {
        this.showRelationRemoveConfirm = false;

        if (action === 'yes') {
            this.updateRelations(this.relationType,
                this.franchiseCourse?.relations?.[this.relationType]?.filter(item => item?.id !== this.relationFranchiseCourse?.id)?.map(item => item?.id) as number[],
                translate('Успешно изтрит курс.'),
                translate('Възникна грешка при изтриването на курс!')
            );
        }
    }

    protected getFranchiseCourses(appendData?: boolean): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.franchiseCourses?.data?.length) {
            if (this.franchiseCourses?.reachEnd()) {
                return;
            }
            this.franchiseCoursesFilter.page = this.franchiseCourses?.nextPage();
        } else if (!this.franchiseCourses?.data?.length) {
            this.franchiseCoursesFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.franchiseService.getCoursesList(this.franchiseId ?? 0, this.franchiseCoursesFilter).subscribe({
            next: response => {
                this.loadingQueue--;

                if (appendData && this.franchiseCourses) {
                    response.data = [
                        ...this.franchiseCourses?.data ?? [],
                        ...response?.data ?? [],
                    ];
                }

                this.franchiseCourses = response;
                this.franchiseCoursesList = this.franchiseCourses?.data?.map(item => {
                    const exists = (this.franchiseCourse?.relations?.[this.relationType]?.find(course => course?.id === item?.id)?.id ?? 0) > 0;

                    return {
                        value: item.id,
                        label: item?.course?.title ?? '',
                        subtitle: item?.title && item?.title !== item?.course?.title && `(${item?.title})`,
                        description: item?.id === this.franchiseCourse?.id ? translate('Текущ курс')
                            : exists ? translate('Вече добавен курс')
                            : translate('Модули:') + ' ' + (item.course?.modules_count ?? '0'),
                        disabled: item?.id === this.franchiseCourse?.id || exists,
                    } as ModalItemsChooseComponentItem;
                })?.slice() ?? [];
            },
            error: error => {
                this.loadingQueue--;

                this.franchiseCoursesList = [];
            }
        });
        this.subscriptions.push(subscription);
    }

    onFranchiseCourseListMore(additional: {q: string, reset: boolean}): void {
        if (additional.reset) {
            this.franchiseCourses = null;
            this.franchiseCoursesFilter.page = 1;
        }

        this.franchiseCoursesFilter.q = additional.q;
        this.getFranchiseCourses(!additional.reset);
    }

    onSelectSectionsToCopy(selected?: number[]): void {
        const courses: number[] = [...new Set([
            ...(this.franchiseCourse?.relations?.[this.relationType]?.map(item => item.id) ?? []) as number[],
            ...(selected ?? [])
        ])];

        this.updateRelations(this.relationType,
            courses,
            translate('Успешно добавен курс.'),
            translate('Възникна грешка при добавянето на курс!')
        );
    }
}
