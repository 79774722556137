import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, TemplateRef, ContentChildren, QueryList, AfterContentInit, ViewChild, ElementRef } from '@angular/core';

import { UploadService } from 'src/app/shared/services/upload.service';
import { UploadModel } from 'src/app/shared/models/upload.model';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { ItemTemplateDirective } from '../../directives/item-template.directive';
import { translate } from '@ngneat/transloco';

export type UploadComponentType = (
     'reader'
    |'image'
    |'file'
    |'avatar'
    |'company'
    |'course'
    |'group'
    |'theory'
    |'presentation'
    |'homework'
    |'project'
    |'blog'
    |'url'
    |'template'
);

export type UploadcomponentActionName = (
      null
    | 'onFileStarted'
    | 'onFileUploaded'
    | 'onFileProgress'
    | 'onFileError'
    | 'onFilesStarted'
    | 'onFilesFinished'
);

export interface UploadComponentQueue {
    index: number;
    file: File;
    chunks: number;
    chunk: number;
    error: any;
    response?: {data: UploadModel}|null;
};

@Component({
    selector: 'upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy, AfterContentInit {
    @ContentChildren(ItemTemplateDirective) templates?: QueryList<any>;

    @ViewChild('inputFile') inputFile: ElementRef|null = null;

    readonly DEFAULT_SIZE: number = 2048;
    readonly DEFAULT_MAX_SIZE: number = 10 * 1024;
    readonly DEFAULT_LABEL: string = 'Choose file';
    readonly DEFAULT_LABEL_UPLOADING: string = 'Upload...';


    // call function when a file is finished
    @Output() onFileStarted: EventEmitter<{file: UploadComponentQueue}> = new EventEmitter();

    // call function when a file is finished
    @Output() onFileUploaded: EventEmitter<{file: UploadComponentQueue, response: any|UploadModel}> = new EventEmitter();

    // call function when progress of file is changed
    @Output() onFileProgress: EventEmitter<{file: UploadComponentQueue, progress: number, totalProgress: number}> = new EventEmitter();

    // call function if there is an error (HTTP or other)
    @Output() onFileError: EventEmitter<{file: UploadComponentQueue, error: any}> = new EventEmitter();

    // call function when a file is finished
    @Output() onFilesStarted: EventEmitter<{files: UploadComponentQueue[]}> = new EventEmitter();

    // when all files are either finished or had errors
    @Output() onFilesFinished: EventEmitter<{files: UploadComponentQueue[]}> = new EventEmitter();


    // Which kind of upload should be performed
    @Input() type: UploadComponentType = 'file';

    // chunk size, kB
    @Input() chunkSize: number = this.DEFAULT_SIZE;

    // how big file can be handled at once, kB
    @Input() maxSize: number = this.DEFAULT_MAX_SIZE;

    // how many files per single query
    @Input() files: number = 1;

    // Button label
    @Input() label: string = this.DEFAULT_LABEL;

    // Button label while uploading
    @Input() labelUploading: string = this.DEFAULT_LABEL_UPLOADING;

    // Set disable state
    @Input() disabled: boolean = false;

    // Set smaller button
    @Input() smallerButton: boolean = false;

    // Custom Class for default appearance
    @Input() customClass: string = '';

    // Custom Button Class for default appearance
    @Input() btnClass: string = '';

    // Limit to extensions list
    @Input() allowMimes: string[] = [];

    templateDefault?: TemplateRef<any>;
    templateLoading?: TemplateRef<any>;
    templateAdditional?: TemplateRef<any>;

    id: string = '_' + Math.random().toString(36).substring(2, 2 + 9);
    buttonLabel: string = this.label || this.DEFAULT_LABEL;
    buttonUploading: string = this.labelUploading || this.DEFAULT_LABEL_UPLOADING;
    isButtonHover: boolean = false;
    uploading: boolean = false;
    progress: number = 0;
    message: string = '';
    messageType: string = '';
    errors: {list: any[], file: string} = {list: [], file: ''};

    protected button: any;
    protected filesQueue: UploadComponentQueue[] = [];

    constructor(
        private uploadService: UploadService,
        private apiValidation: ApiValidation,
    ) { }

    ngOnInit(): void {
        this.buttonLabel = this.label || this.DEFAULT_LABEL;
        this.buttonUploading = this.labelUploading || this.DEFAULT_LABEL_UPLOADING;
    }

    ngOnDestroy(): void {

    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch (item.getType()) {
                case 'default':
                    this.templateDefault = item.template;
                    break;

                case 'loading':
                    this.templateLoading = item.template;
                    break;

                case 'additional':
                    this.templateAdditional = item.template;
                    break;
            }
        });
    }

    onFileSelected(event: InputEvent|any): void {
        if (this.uploading) {
            return;
        }

        this.filesQueue = Array.prototype.slice.call((event.target as HTMLInputElement).files, 0, this.files).map((item: File, index: number) => ({
            index,
            file: item,
            chunk: 0,
            chunks: this.getChunksCount(item),
            error: null,
            response: null,
        }));

        if (this.allowMimes?.length) {
            this.filesQueue = this.filesQueue.map(file => {
                if (this.allowMimes.indexOf(file.file.type) < 0) {
                    file.error = translate("Файлът е неподдържан формат.");
                    this.onFileError.emit({file, error: file.error});
                }
                return file;
            });
        }
        this.proceedUpload();
    }


    protected getChunksCount(file: File): number {
        const size = Math.abs(this.chunkSize || 2048) * 1024;
        return Math.ceil(file.size / size);
    }

    protected getChunks(file: File, chunk: number = 0): Blob {
        const size = Math.abs(this.chunkSize || 2048) * 1024;
        let chunksCount = this.getChunksCount(file);
        chunk = chunk < 0 ? 0 : chunk >= chunksCount ? chunksCount - 1 : chunk;

        return file.slice(
            chunk * size, Math.min(chunk * size + size, file.size), file.type
        );
    }

    protected async proceedUpload() {
        this.uploading = true;
        this.progress = 0;
        this.errors = {file: '', list: []};
        this.setProgressButton(true);

        this.onFilesStarted.emit({files: this.filesQueue});

        for (let index in this.filesQueue) {

            let item = this.filesQueue[index];
            let chunks = item.chunks && item.chunks > 1 ? item.chunks : undefined;

            if (item.error) {
                continue;
            }

            this.onFileStarted.emit({file: item});

            let fileChunk: Blob|null = null;

            for (let chunk = 0, uploaded = 0; chunk < item.chunks; chunk++) {
                fileChunk = null;

                try {
                    if (item.file.size > (this.maxSize || this.DEFAULT_MAX_SIZE) * 1024) {
                        throw translate("Файлът е прекалено голям.");
                    }

                    const callback = (event: any) => {

                        if ((event.loaded / event.total * 100) >= 99.999) {
                            uploaded += fileChunk?.size || 0;
                        }

                        let progress = Math.floor((uploaded * 100) / item.file.size);
                        progress = progress <= 100 ? progress : 100;

                        let totalProgress = Math.round(((100 * Number(index)) + progress) / this.filesQueue.length);
                        totalProgress = totalProgress <= 100 ? totalProgress : 100;

                        this.buttonLabel = this.labelUploading;
                        this.progress = totalProgress;

                        this.onFileProgress.emit({file: item, progress, totalProgress});
                    };

                    let response = await (async () => new Promise((response, reject) => {
                        fileChunk = this.getChunks(item.file, chunk);

                        if (this.type === 'reader') {
                            const reader = new FileReader;
                            reader.addEventListener("load", () => {
                                response(reader.result);
                            }, false);
                            reader.addEventListener("error", (error) => {
                                reject(error);
                            }, false);

                            reader.readAsText(this.inputFile?.nativeElement?.files[0] ?? null)
                            return;
                        }

                        this.uploadService.makeRequest(this.type, item.file.name, fileChunk, chunks, chunk, callback).subscribe({
                            next: (data: any) => response(data),
                            error: (error: any) => reject(error)
                        });
                    }))();

                    if ((item.chunks - 1) <= chunk) {
                        item.response = response as {data: UploadModel};
                        this.onFileUploaded.emit({file: item, response});
                    }
                } catch (error) {
                    try {
                        item.error = error;
                        this.errors = await this.apiValidation.handleServerError(error);
                        item.error = this.errors['file'] || translate('Грешка!');
                    } catch (e) { } finally {
                        this.onFileError.emit({file: item, error});
                    }
                    break;
                }
            }
        }

        this.uploading = false;
        this.buttonLabel = this.label || this.DEFAULT_LABEL;
        this.progress = 0;

        this.setProgressButton(false);
        this.onFilesFinished.emit({files: this.filesQueue});

        // reset value so when user adds same file to trigger
        // the 'change' event (onFileSelected) again
        this.inputFile && (this.inputFile.nativeElement.value = null);
    }

    protected setProgressButton(toggle: boolean = true) {
        // if (toggle && this.button.current) {
        //     this.button.current.style.minWidth = ~~this.button.current.getBoundingClientRect().width + 'px';
        // } else if (this.button.current) {
        //     this.button.current.style.minWidth = 'auto';
        // }
    }
}
