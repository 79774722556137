import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[requiredField]'
})
export class RequiredFieldDirective implements OnInit, OnDestroy {
    protected readonly DEBUG: boolean = !environment.production;
    protected timer: any = null;
    protected innerRequiredFieldDynamic: boolean = false;

    private subscription?: Subscription;

    constructor(private el: ElementRef, private control: NgControl) {}

    ngOnInit() {
        this.updateStyle();

        if (this.requiredFieldDynamic) {
            this.subscription?.unsubscribe();
            this.subscription = this.control?.control?.statusChanges?.subscribe((data) => {
                this.updateStyle();
            });
        }
    }

    ngOnDestroy() {
        this.timer && clearTimeout(this.timer);

        this.subscription?.unsubscribe();
    }

    @Input('requiredFieldAttribute') requiredFieldAttribute: string = 'id';
    @Input('requiredFieldClass') requiredFieldClass: string = 'required';
    @Input('requiredFieldDynamic')
    set requiredFieldDynamic(val: boolean) {
        if (this.innerRequiredFieldDynamic !== val) {
            this.innerRequiredFieldDynamic = val;

            this.subscription?.unsubscribe();
            if (val) {
                this.subscription = this.control?.control?.statusChanges?.subscribe((data) => {
                    this.updateStyle();
                });
            }
        }
    }
    get requiredFieldDynamic(): boolean {
        return this.innerRequiredFieldDynamic;
    }

    @HostListener('input') onInput() {
        this.updateStyle();
    }

    private updateStyle() {
        this.timer && clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            try {
                if (this.control?.name) {
                    const inputElement = this.el.nativeElement;
                    const selector = 'label[for=' + (inputElement.getAttribute(this.requiredFieldAttribute) ?? this.requiredFieldAttribute) + ']';
                    const labelElement = inputElement.closest('form')?.querySelector(selector) ?? document?.querySelector(selector);
                    const isRequired = !!(this.control.control?.validator && this.control.control.validator({} as any)?.['required']);
                    labelElement?.classList.toggle(this.requiredFieldClass, isRequired);
                }
            } catch (e) {
                this.DEBUG && console.log("requiredField", e);
            }
        }, 10);
    }
}
