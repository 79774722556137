import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { translate } from '@ngneat/transloco';
import * as moment from 'moment';

export interface ModalDateChooserComponentAction {
    name?: string;
    action?: string;
    btnClass?: string;
    tooltip?: string;
    icon?: string;
};

@Component({
    selector: 'modal-date-chooser',
    templateUrl: './modal-date-chooser.component.html',
    styleUrls: ['./modal-date-chooser.component.scss'],
})
export class ModalDateChooserComponent implements OnInit, OnDestroy {
    protected innerDate: Date|null = null;

    @Output() onAction: EventEmitter<string> = new EventEmitter();

    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @Output() onHide: EventEmitter<any> = new EventEmitter();

    @Output() onDateApply: EventEmitter<Date|null> = new EventEmitter();

    @Input() loading: boolean = false;
    @Input() headline: string|null = translate('Избор на дата');
    @Input() body: string|null = '';

    @Input() actions: ModalDateChooserComponentAction[] = [{
        name: translate('Избор'), action: 'yes', btnClass: 'btn-primary', tooltip: translate('Потвърждение'), icon: 'fa-solid fa-check fa-fw'
    }, {
        name: translate('Отказ'), action: 'no', btnClass: 'btn-secondary', tooltip: translate('Отказване'), icon: 'fa-solid fa-xmark fa-fw'
    }];


    calendarActions: ModalDateChooserComponentAction[] = [{
        name: translate('Днес'), action: 'today', btnClass: 'btn-outline-primary btn-sm', tooltip: translate('Избор на днешна дата')
    }, {
        name: translate('Изчисти'), action: 'clear', btnClass: 'btn-outline-primary btn-sm', tooltip: translate('Изчисти избраната дата')
    }];

    @Input() skippable: boolean = false;
    @Input() closable: boolean = false;


    @Input()
    set date(date: Date|string|null) {
        if (date !== this.innerDate) {
            if (typeof date === 'string') {
                date = moment(date)?.toDate();
            }

            this.innerDate = date as Date|null;
        }
    }
    get date(): Date|null {
        return this.innerDate;
    }

    constructor() { }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    onCloseAction(e: any): void {
        if (this.skippable) {
            this.onAction.emit('close');
        }
    }

    onButtonClicked(event: Event, action?: string): void {
        event.preventDefault();

        switch (action) {

            case 'clear':
                this.date = null;
                break;
            case 'today':
                this.date = moment().toDate();
                break;

            case 'yes':
                this.onDateApply.emit(this.date);
                this.onAction.emit(action);
                break;
            default:
                this.onAction.emit(action);
        }
    }

    onShowDialog(event?: any): void {
        this.onShow.emit(event);
    }

    onHideDialog(event?: any): void {
        this.onHide.emit(event);
    }

}
